import { defineStore } from 'pinia';
import { gql } from '@apollo/client/core';

export const useBrandsStore = defineStore('brands', () => {
  const brands = ref<any[]>([]);
  const loading = ref(false);
  const loaded = ref(false);
  const error = ref(false);

  async function fetchBrands() {
    if (loaded.value || loading.value) {
      return;
    }
    loading.value = true;
    const { data: brandsData, error: brandsError } = await useAsyncQuery({
      query: gql`
        query Brands {
          brands {
            edges {
              node {
                id
                entityId
                name
                path
                slug
              }
            }
          }
        }
      `,
    });

    if (!brandsError.value) {
      error.value = false;
      loaded.value = true;

      brands.value = brandsData?.value?.data?.brands?.edges?.map(
        (edge: any) => ({
          ...edge.node,
          path: edge.node.slug,
        })
      );
    } else {
      error.value = true;
    }
    loading.value = false;
  }

  return {
    fetchBrands,
    brands,
    loading,
    loaded,
    error,
  };
});
